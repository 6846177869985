import React from "react"
import { StaticImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { Mail, Instagram } from 'react-feather'
import { dataArtworks } from "../data/DataSite"

export default function About({ data }) {
    return (
        <Layout currentPage="about" children={
            <>
                {/* <!-- Start main about section --> */}
                <section>
                    <div className="container pt-5 mt-5">
                        <h1 className="text-secondary mb-3 mt-4 mt-md-0">About</h1>
                        {/* Flexbox rules for sm & below to ensure image is center aligned */}
                        <div className="row d-sm-flex justify-content-sm-center">
                            <div className="col-md-7 order-md-1 order-2">
                                <p>I’ve always loved painting and computers. Give me a blank piece of paper, I start sketching (it’s spontaneous, can’t help it). Give me a computer and I start designing stuff. From doodling in MS Paint, to graphic design and now user experience.</p>
                                <p>While pursuing Computer Science in my undergrad, I fell in love with app development. The ability to see ideas visualized with a few lines of code was fascinating to me. I wanted to create great product experiences.</p>
                                <p>However, to make that happen, I learned that it wasn't enough to write the best code possible. It was important to understand people's needs and to solve for that first.
                                </p>
                                <p>So now I focus on doing just that. Understanding problems and needs to design and build better experiences.</p>
                                <a href="mailto:hi@iamsuleiman.com?Subject:-via%20Your%20Portfolio" className="btn btn-primary px-4 mt-3"><Mail />Get in touch</a>
                            </div>
                            <div className="col-sm-6 col-md-5 order-md-2 order-1 mb-4 mb-md-0 text-center">
                                <StaticImage
                                    src="../../static/img/suleiman_about.jpg"
                                    className="img-about img-fluid rounded border"
                                    alt="Suleiman Ali Shakir"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End main about section --> */}

                {/* Start artwork section */}
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-secondary">Artwork</h2>
                                <p>When I'm not designing or writing code, you'll find me enjoying digital, or sometimes traditional painting.</p>
                            </div>
                        </div>

                        <div className="row mt-3">
                            {
                                dataArtworks.map((artwork) => (
                                    <div key={artwork.id} className="col-6 col-md-3 mb-4 mb-md-0">
                                        <GatsbyImage className="img-fluid rounded border" image={getCorrectImg(data, artwork.id)} alt={artwork.alt} />
                                    </div>
                                ))
                            }
                        </div>

                        <div className="row mt-5">
                            <div className="col-sm-12 text-center">
                                <a href="https://www.instagram.com/suleiman.art/" target="_blank" rel="noreferrer" className="btn btn-outline-primary"><Instagram />See more art</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End artwork section */}

            </>} />
    )
}

export const artImage = graphql`
    fragment artImage on File {
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
`
export const queryArt = graphql`
  query {
    image1: file(relativePath: { eq: "artwork/artwork-chadwick.jpg" }) {
      ...artImage
    }
    image2: file(relativePath: { eq: "artwork/artwork-niagara.jpg" }) {
      ...artImage
    }
    image3: file(relativePath: { eq: "artwork/artwork-babyyoda.jpg" }) {
      ...artImage
    }
    image4: file(relativePath: { eq: "artwork/artwork-portraitstudy.jpg" }) {
        ...artImage
    }
  }
`

function getCorrectImg(data, key) {
    switch (key) {
        case 'art-chadwick':
            return getImage(data.image1);
        case 'art-niagara':
            return getImage(data.image2);
        case 'art-babyyoda':
            return getImage(data.image3);
        case 'art-portraitstudy':
            return getImage(data.image4);
        default:
            return getImage(data.image4);
    }
}
